import { lazy } from 'react';
import locales from './locales';
import routes from './routes';
import themes from './themes';
import parseLanguages from 'base-shell/lib/utils/locale';
import grants from './grants';
import Loading from 'material-ui-shell/lib/components/Loading/Loading';
import {
  defaultUserData,
  // isGranted,
  // isAnyGranted,
} from 'rmw-shell/lib/utils/auth';
import { getDefaultRoutes } from './getDefaultRoutes';
import { createNewUser, getUserData } from './models/user';
import { preLoadCanny } from 'utils/scriptsUtils';

const env =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || 'development';
const appConfig = {
  development: {
    env,
    gaEnabled: true,
    gaTrackingKey: 'G-FFRCC5T347',
    serviceUrl: 'http://localhost:8087/api/v1/',
    appEngineUrl: 'http://localhost:8087/api/v1/',
    // serviceUrl:
    //   'https://staging-dot-instaresume-backend.el.r.appspot.com/api/v1/',
    // appEngineUrl:
    //   'https://staging-dot-instaresume-backend.el.r.appspot.com/api/v1/',
    paymentHookEnabled: true,
  },
  staging: {
    env,
    gaTrackingKey: 'G-FFRCC5T347',
    gaEnabled: true,
    paymentHookEnabled: true,
    serviceUrl:
      'https://staging-dot-instaresume-backend.el.r.appspot.com/api/v1/',
    appEngineUrl:
      'https://staging-dot-instaresume-backend.el.r.appspot.com/api/v1/',
  },
  production: {
    env,
    gaTrackingKey: 'G-JKFDZ57PK8',
    gaEnabled: true,
    paymentHookEnabled: true,
    serviceUrl: 'https://api.instaresu.me/api/v1/',
    appEngineUrl: 'https://instaresume-backend.el.r.appspot.com/api/v1/',
  },
}[env];

async function getLocation() {
  try {
    if (localStorage.getItem('USER_LOCATION')) {
      return JSON.parse(localStorage.getItem('USER_LOCATION'));
    }
    // const response = await axios.request(
    //   getConfig(`location/country`, '', 'get'),
    // );

    const response = await (
      await fetch(`${appConfig.serviceUrl}location/country`)
    ).json();
    let finalResult = response.data;
    localStorage.setItem('USER_LOCATION', JSON.stringify(response.data));
    return finalResult;
  } catch (error) {
    const response = { country: 'US' };
    return response;
  }
}

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: 'AIzaSyALP_k4kRSZENxHyqtMp9GAy3J6gs4o5Fs',
        authDomain: 'instaresume-backend.firebaseapp.com',
        projectId: 'instaresume-backend',
        storageBucket: 'instaresume-backend.appspot.com',
        messagingSenderId: '40650815269',
        appId: '1:40650815269:web:a556ae239aafea96cc4ccc',
        measurementId: 'G-JKFDZ57PK8',
        databaseURL:
          'https://instaresume-backend-default-rtdb.asia-southeast1.firebasedatabase.app/',
      },
      messaging: {
        publicVapidKey:
          'BImIpEGseNp8oOfSMNWdqxuyygMQgWpo-sVgJ65LHTWlShNNiQUa7vRYoM1On2BKU6befEZ8XDR7FSlsrmQidAs',
      },
    },
    devp: {
      initConfig: {
        apiKey: 'AIzaSyAjjsx1S3Fb83CuRiIYyTj0SpoUKTBWMmA',
        authDomain: 'resume-builder-d9cb3.firebaseapp.com',
        projectId: 'resume-builder-d9cb3',
        storageBucket: 'resume-builder-d9cb3.appspot.com',
        messagingSenderId: '770692783384',
        appId: '1:770692783384:web:4b6743c7a85a41c1b8926d',

        databaseURL:
          'https://resume-builder-d9cb3-default-rtdb.asia-southeast1.firebasedatabase.app',
      },
      messaging: {
        publicVapidKey:
          'BPnnhdzyJP2G2hYzkyUqW21Oiq9TRBQhKF3NEc-gpIXspvlblF8L9IYCCsU1Fi17HS0NQ7qjF7LV6De8d4YpTCs',
      },
    },
    dev: {
      initConfig: {
        apiKey: 'AIzaSyAjjsx1S3Fb83CuRiIYyTj0SpoUKTBWMmA',
        authDomain: 'resume-builder-d9cb3.firebaseapp.com',
        projectId: 'resume-builder-d9cb3',
        storageBucket: 'resume-builder-d9cb3.appspot.com',
        messagingSenderId: '770692783384',
        appId: '1:770692783384:web:4b6743c7a85a41c1b8926d',

        databaseURL:
          'https://resume-builder-d9cb3-default-rtdb.asia-southeast1.firebasedatabase.app',
      },
      messaging: {
        publicVapidKey:
          'BPnnhdzyJP2G2hYzkyUqW21Oiq9TRBQhKF3NEc-gpIXspvlblF8L9IYCCsU1Fi17HS0NQ7qjF7LV6De8d4YpTCs',
      },
    },
    firebaseuiProps: {
      signInOptions: ['google.com', 'github.com', 'twitter.com', 'password'],
    },
  },
  googleMaps: {
    apiKey: 'AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g',
  },
  auth: {
    grants,
    redirectTo: '/resumes',
    persistKey: 'base-shell:auth',
    signInURL: '/signin',
    onAuthStateChanged: async (user, auth) => {
      // const { getDatabase, ref, onValue, get, update, off } = await import(
      const { getDatabase, ref, off } = await import('firebase/database');
      const db = getDatabase();

      try {
        if (user != null) {
          getUserData(user)
            .then(async (userData) => {
              const updatedUserData = {
                resumeLimit: (userData && userData.resumeLimit) || 1,
              };
              if (userData.hasOwnProperty('AICredits')) {
                updatedUserData.AICredits = userData.AICredits;
              }
              if (userData.hasOwnProperty('RUCredits')) {
                updatedUserData.RUCredits = userData.RUCredits;
              }
              getLocation().then((response) => {
                updatedUserData.country =
                  response?.country || response?.data?.country || 'US';
                auth.updateAuth({
                  ...defaultUserData(user),
                  ...updatedUserData,
                  authLoaded: true,
                });
              });
              const idToken = await user.getIdToken();
              window?.chrome?.runtime?.sendMessage(
                'manaafnpapjdakolicnedahfebalepcf',
                { type: 'AUTH_UPDATED', token: idToken },
                (response) => {
                  // window.postMessage({type: "TAB_URL", url: response.url}, "*");
                },
              );
            })
            .catch((err) => {
              console.warn(err);
            });
          // const grantsSnap = await get(ref(db, `user_grants/${user.uid}`));
          // const notifcationsDisabledSnap = await get(
          //   ref(db, `disable_notifications/${user.uid}`),
          // );

          // const isAdminSnap = await get(ref(db, `admins/${user.uid}`));

          // onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
          //   auth.updateAuth({ grants: snap.val() });
          // });
          // onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
          //   auth.updateAuth({ notificationsDisabled: !!snap.val() });
          // });
          // onValue(ref(db, `admins/${user.uid}`), (snap) => {
          //   auth.updateAuth({ isAdmin: !!snap.val() });
          // });

          auth.updateAuth({
            ...defaultUserData(user),
            // grants: grantsSnap.val(),
            // notificationsDisabled: notifcationsDisabledSnap.val(),
            // isAdmin: !!isAdminSnap.val(),
            // isGranted,
            // isAnyGranted,
          });
          await createNewUser(user);
          try {
            const timeout = preLoadCanny() ? 0 : 2000;
            setTimeout(() => {
              window.Canny('identify', {
                appID: '6545be389469c2d07be7dc60',
                user: {
                  avatarURL: user.photoURL, // optional
                  // created: new Date(user.created).toISOString(), // optional
                  email: user.email,
                  id: user.uid,
                  name: user.displayName,
                },
              });
            }, timeout);
          } catch (err) {
            console.warn(err);
          }

          // update(ref(db, `users/${user.uid}`), {
          //   displayName: user.displayName,
          //   uid: user.uid,
          //   photoURL: user.photoURL,
          //   providers: user.providerData,
          //   emailVerified: user.emailVerified,
          //   isAnonymous: user.isAnonymous,
          //   notificationsDisabled: notifcationsDisabledSnap.val()
          // });

          // update(ref(db, `user_chats/${user.uid}/public_chat`), {
          //   displayName: 'Public Chat',
          //   lastMessage: 'Group chat',
          //   path: `group_chat_messages/public_chat`
          // });
        } else {
          off(ref(db));

          auth.setAuth(defaultUserData(user));
          window?.chrome?.runtime?.sendMessage(
            'manaafnpapjdakolicnedahfebalepcf',
            { type: 'AUTH_UPDATED', token: null },
            (response) => {
              // window.postMessage({type: "TAB_URL", url: response.url}, "*");
            },
          );
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  getDefaultRoutes: getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: 'base-shell:locale',
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    onError: (e) => {
      //console.warn(e)

      return;
    },
  },
  menu: {
    MenuContent: lazy(() => import('../components/Menu/MenuContent')),
    MenuHeader: lazy(() =>
      import('material-ui-shell/lib/components/MenuHeader/MenuHeader'),
    ),
  },
  theme: {
    themes,
    defaultThemeID: 'standard',
    defaultType: 'light',
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound')),
  },
  components: {
    Menu: lazy(() => import('material-ui-shell/lib/containers/Menu/Menu')),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() => import('../containers/LayoutContainer/index')),
  },
  appConfig,
};

export default config;
export { appConfig as AppConfig };
